<template>
	<div class="block social purple disabled">
		<router-link :to="{path: `/client/${getClientSlug}/paid-social`}">
			<h3>Paid Social</h3>
			<div class="row">
				<p>Facebook paid conversions</p>
				<div class="stats">
					<p class="number">{{ conversions }}</p>
					<div class="conversion-rate up">
						<div class="grid-container">
							<div class="col">
								<p>{{ conversionRate }}</p>
							</div>
							<div class="col">
								<span class="arrow">
									<i class="fa-solid fa-arrow-up-long"></i>
								</span>
							</div>
						</div>
					</div>
					<!-- end conversion-rate -->
				</div>
				<!-- end stats -->
			</div>
			<!-- end row -->
			<div class="row graph-row">
				<div class="graph">
					<span></span>
					<img class="graph" v-bind:src="graph" />
				</div>
				<!-- end graph -->
			</div>
			<!-- end row -->
			<span class="arrow-link">Paid Social information</span>
		</router-link>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

let fbConversions = "902"
let conversionPercent = "100%"

export default {
	data() {
		return {
			graph: require("../../assets/img/purple-graph.png"),
			conversions: fbConversions === "" ? "--" : fbConversions,
			conversionRate: conversionPercent === "" ? "--" : conversionPercent,
		}
	},
	computed: {
		...mapGetters("client", ["getClientSlug"]),
	},
}
</script>
