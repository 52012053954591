<template>
	<div class="block content pink" v-bind:class="active">
		<router-link :to="{path: `/client/${getClientSlug}/content`}">
			<h3>Content</h3>
			<div class="circle-chart">
				<div class="circle">
					<svg viewBox="0 0 36 36" class="chart">
						<defs>
							<filter id="glow">
								<feDropShadow dx="0" dy="0" stdDeviation="0.5" flood-color="#E67497" />
							</filter>
						</defs>
						<path class="track" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
						<path class="circle" style="filter: url(#glow)" v-bind:stroke-dasharray="circleValue + ', 100'" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
						<text x="18" y="14" class="reach">Reach</text>
						<text x="18" y="20.35" class="percentage">{{ numberWithCommas(getClientContentTreeReach) || 0 }}</text>
					</svg>
				</div>
				<!-- end circle -->
			</div>
			<!-- end circle-chart -->
			<span class="arrow-link">Access content</span>
		</router-link>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
	data() {
		return {
			loginType: "client",
			// circleValue: "121,767",
		}
	},
	computed: {
		...mapGetters("client", ["getClientSlug", "getClientContentTreeReach"]),
	},
}
</script>
