<template>
	<section class="client-overview three-columns">
		<div class="overview-blocks grid-container">
			<ContentBlock></ContentBlock>
			<PaidSocialBlock></PaidSocialBlock>
			<OrganicSocialBlock></OrganicSocialBlock>
			<AnalyticsBlock></AnalyticsBlock>
			<SeoBlock></SeoBlock>
			<PpcBlock></PpcBlock>
			<CampaignBlock></CampaignBlock>
		</div>
		<!-- end overview-blocks -->
	</section>
</template>

<script>
import ContentBlock from "../../components/client/ContentBlock.vue"
import PaidSocialBlock from "../../components/client/PaidSocialBlock.vue"
import OrganicSocialBlock from "../../components/client/OrganicSocialBlock.vue"
import AnalyticsBlock from "../../components/client/AnalyticsBlock.vue"
import SeoBlock from "../../components/client/SeoBlock.vue"
import PpcBlock from "../../components/client/PpcBlock.vue"
import CampaignBlock from "../../components/client/CampaignBlock.vue"
import {mapGetters} from "vuex"

export default {
	data() {
		return {
			loginType: "client",
		}
	},
	components: {
		ContentBlock,
		PaidSocialBlock,
		OrganicSocialBlock,
		AnalyticsBlock,
		SeoBlock,
		PpcBlock,
		CampaignBlock,
	},
	computed: {
		...mapGetters("client", ["getClientSlug"]),
	},
}
</script>
<style lang="scss" scoped>
//@import "./src/assets/scss/dashboard.scss";
</style>
