<template>
	<div class="block performance blue span-3 disabled">
		<router-link :to="{path: `/client/${getClientSlug}/campaign`}">
			<div class="row two-columns">
				<div class="grid-container">
					<div class="col">
						<h3>Campaign Performance</h3>
					</div>
					<div class="col last align-right">
						<span class="arrow-link no-border">View latest report</span>
					</div>
				</div>
				<!-- end grid-container -->
			</div>
			<!-- end row -->
		</router-link>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
	data() {
		return {}
	},
	computed: {
		...mapGetters("client", ["getClientSlug"]),
	},
}
</script>
